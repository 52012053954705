import React, { Component } from 'react'

import SEO from '../../components/SEO/SEO'
import Layout from '../../Layout/Layout'
import Hero from '../../components/Hero/Hero'
import Header from '../../components/Header/Header'
import Subheader from '../../components/Subheader/Subheader'
import Container from '../../components/Container/Container'

import HeroImage from '../../assets/images/thumb1.jpg'

class BlogPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Jak skutecznie wynająć zwyżkę lub podnośnik koszowy"
          keywords={[
            'wynajem zwyzki rzeszow',
            'zwyzka mielec',
            'podnosnik koszowy janow lubelski',
            'prace na wysokosciach',
            'wypozyczenie podnosnika teleskopowego',
          ]}
        />
        <Hero image={HeroImage}>
          <Header>Jak skutecznie wynająć zwyżkę lub podnośnik koszowy</Header>
          <Subheader>
            Wszyscy właściciele domów znają to uczucie. Wielokrotnie zdarza się,
            że potrzebujemy wykonać jakieś prace na wysokości. Czasami są to
            czynności związane z remontem. Czasami są to prace ogrodnicze, jak
            wycinka drzew. We wszystkich tych sytuacja bardzo pomocne są
            urządzenia ułatwiające wszelkie prace na wysokościach.
          </Subheader>
        </Hero>
        <Container columns={1}>
          <p>
            Aby bezpiecznie przeprowadzić wszelkie zadania na większej wysokości
            warto zgłosić się do profesjonalistów z doświadczeniem.
            Bezpieczeństwo jest dla nas najważniejsze. Wysoka jakość sprzętu,
            którym dysponujemy pozwala nam podejmować się nawet najbardziej
            wymagających zleceń.
          </p>

          <Header as="h3">
            W jakiej sytuacji przyda nam się zwyżka lub podnośnik koszowy?
          </Header>
          <p>
            Podnośniki koszowe ułatwiają wszelkie prace na wysokościach i
            pozwalają na skuteczne wykonanie określonych usług.
            Proponujemy podnośnik koszowy z obsługą operatorską o zasięgu do 28
            metrów. Nasze podnośniki posiadają certyfikat CE, oznaczający wysoki
            stopień bezpieczeństwa.
          </p>
          <p>
            Kiedy potrzebna staje się zwyżka do prac ogrodniczych,
            zapewniamy podnośnik koszowy, dzięki któremu przycinanie lub
            wycinanie drzew będzie skuteczne, szybkie, a przede wszystkim
            bezpieczne. Jest to ważne, kiedy drzewa rosną wzdłuż linii wysokiego
            napięcia lub w pobliżu zabudowań.
          </p>
          <p>
            Podnośniki halowe, elektryczne – przystosowane do prac na kostce,
            posadzce, betonie i asfalcie. Idealne do montażu instalacji
            wentylacyjnych na halach. Mają zasięg do 12 metrów, a udźwig do 360
            kg. Sprawdzają się również przy montażu bram i okien oraz wszędzie,
            gdzie konieczna jest konserwacja konstrukcji nośnych.
          </p>

          <Header as="h3">Dźwigi w pracach budowlanych</Header>
          <p>
            Warto korzystać pomocy z podnośnika koszowego przy pracach
            ogólnobudowlanych ze względu na fakt, że łatwo można się z niego
            dostać do każdego miejsca na budynku.
          </p>
          <p>
            Podnośnik koszowy polecany  jest również przy pracach dekarskich,
            przy budynkach o rozmaitej architekturze, również tam, gdzie ciężko
            się dostać.
          </p>

          <Header as="h3">Gdzie szukać pomocy z wynajmem zwyżki?</Header>
          <p>
            Jeśli interesuje Państwa wynajem podnośników koszowych. Rzeszów,
            Stalowa Wola i okolice to miejsca, w których działamy – ułatwiając
            pracę osobom prywatnym i przedsiębiorcom. Opinie zadowolonych
            klientów pomagają nam pracować z coraz większym zaangażowaniem.
          </p>
        </Container>
      </Layout>
    )
  }
}

export default BlogPage
